export default {
    silmekont: false,
    solkontrol: false,

    domainId: 'bos',
    domainName: '',
    
    //ONLY SANDIKLI BELEDIYESI (E - HIZMETLER LINKLERI)
    sandikliBelediye:'https://ebelediye.sandikli.bel.tr/webportal/index.php',
    sandikliMezarlik:'https://keos.sandikli.bel.tr/keos/?p=MEBIS&MID=67',
    sandikliIlce:'https://keos.sandikli.bel.tr/keos/',
    sandikliImar:'https://keos.sandikli.bel.tr/imardurumu/index.aspx',

    /// Foto yükleme degiskenleri

    uploadtaskk: null,
    progres1: null,

    /// Veri çekme Değişkenleri

    BelediyeGenelBilgiler: {
        belediyeadi: '',
        belediyeadresi: '',
        faxnumarasi: '',
        ilceadi: '',
        iletisim: '',
        kapak: '',
        mail: '',
        websiteDailyCounter: null,
    },
    BaskanBilgileri: {
        baskanadi: '',
        baskanslogan: '',
        baskanface: '',
        baskantwit: '',
        baskaninsta: '',
        baskanprofil: '',
        ozgecmis: '',
        mesaj: '',
        Dosyalar: [],
    },
    IlceBilgiler: {
        tarihce: '',
        Dosyalar: []
    },
    Menuler: {},
    Linkler: {},

    Birimler: {},
    Uyeler: {},
    Muhtarlar: {},
    EskiBaskanlar: {},
    Encumenlar: {},
    Kararlar: {},
    Cralanlar: {},
    MeclisGundemler: {},
    Raporlar: {},
    Projeler: {},
    Haberler: {},
    Sehitler: {},
    Slides: {},
    Yerler: {},
    Vefatlar: {},
    Evlenenler: {},
    Belgeler: {},
    DuyuruIlanlar: {},
    Ihale:{},
    ihalekontrol: false,
    duyuruilankontrol: false,
    belgekontrol: false,
    evlenenkontrol: false,
    vefatkontrol: false,
    yerkontrol: false,
    sehitkontrol: false,
    ilcekontrol: false,
    haberkontrol: false,
    projekontrol: false,
    genelkontrol: false,
    baskankontrol: false,
    raporlarkontrol: false,
    birimlerkontrol: false,
    muhtarlarkontrol: false,
    eskibaskanlarkontrol: false,
    encumenlarkontrol: false,
    kararlarkontrol: false,
    cralanlarkontrol: false,
    meclisgundemlerkontrol: false,
    uyelerkontrol: false,
    slideskontrol: false,
    menukontrol: false,
    linkkontrol: false,

}