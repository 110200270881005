<template>
  <div v-if="display == true" class="rizaMetni text">
    <p class="text-center p-2">
      Sunduğumuz hizmetlerde siz değerli vatandaşlarımızın deneyimini
      geliştirmek için başta 6698 Sayılı Kişisel Verilerin Korunması Kanunu
      olmak üzere tabi olduğumuz mevzuata uygun şekilde web sitemizde çerezler
      kullanmaktayız. İşbu metinde internet sitemizin kullanıcıları (“veri
      sahibi”) tarafından çerezlerin kullanımı ile elde edilen kişisel verilerin
      işlenmesine ilişkin bilgi verilmiştir. Gizliliğin bizim için temel
      ilkelerden olduğunu ve bu amaçla kurumumuzun sistem ve internet
      altyapısını en güvenilir seviyede tutarak gerekli önlemleri aldığımızı
      belirtmek isteriz. &nbsp; Devam etmek için lütfen Çerezleri Onaylayın!
      &nbsp;<span @click="onaylaKapat()" class="onayla btn btn-outline-warning">
        Onayla</span
      >
      &nbsp;
      <span @click="onaylaKapat()" class="kapat btn">Kapat</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Rıza-Metni",
  data() {
    return {
      display: true,
    };
  },
  methods: {
    onaylaKapat() {
      this.display = false;
    },
  },
};
</script>

<style>
.rizaMetni {
  display: flex;
  position: fixed;
  z-index: 9999;
  background-color: blue;
  color: white;
  bottom: 0;
}
.rizaMetni p {
  font-size: 14px;
}
.onayla,
.kapat {
  cursor: pointer;
}
.kapat {
  color: black;
}
</style>