<template>
  <div>
    <header class="main-header header-style-one">
      <!-- Header Top -->

      <div class="header-top header-top-one">
        <div class="auto-container">
          <div class="inner clearfix">
            <div class="top-left clearfix">
              <div class="welcome-text">
                <span></span>{{ Belediyebilgiler.belediyeadi }} Belediyesi Resmi
                Web Sitesi
              </div>
            </div>

            <div class="top-right clearfix">
              <div class="email d-none d-md-inline-block">
                <router-link to="/iletisim">
                  <span class="icon fa fa-envelope"></span>İstek Formu
                </router-link>
              </div>
              <div class="email d-none d-md-inline-block">
                <a :href="'mailto:' + Belediyebilgiler.mail">
                  <span class="icon fa fa-envelope"></span
                  >{{ Belediyebilgiler.mail }}
                </a>
              </div>
              <div class="email">
                <a :href="'tel:' + Belediyebilgiler.iletisim">
                  <span class="icon fa fa-phone-alt"></span
                  >{{ Belediyebilgiler.iletisim }}
                </a>
              </div>

              <div class="hours">
                <div class="hours-btn">
                  Hızlı Menü
                  <span class="arrow flaticon-down-arrow"></span>
                </div>

                <div class="hours-dropdown">
                  <ul>
                    <li v-if="domAd != 'www.eymir.bel.tr'">
                      <a :href="Belediyebilgiler.ebelediye" target="blank"
                        >E-Belediye</a
                      >
                    </li>
                    <li v-if="domAd != 'www.eymir.bel.tr'">
                      <router-link to="/vefat">Vefat Edenler</router-link>
                    </li>

                    <li v-if="domAd != 'www.eymir.bel.tr'">
                      <router-link to="/kentrehberi">Kent Rehberi</router-link>
                    </li>

                    <li>
                      <router-link to="/belgeler">Belgeler</router-link>
                    </li>

                    <li>
                      <router-link to="/duyuruilanlar">Duyurular</router-link>
                    </li>

                    <li>
                      <router-link to="/galeri">Galeri</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Header Upper -->

      <div class="header-upper">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <!--Logo-->

            <div class="logo-box clearfix">
              <div class="logo">
                <router-link to="/" title="">
                  <img :src="Belediyebilgiler.kapak" alt="" title="" />
                </router-link>
              </div>
            </div>

            <!--Nav-->

            <div class="nav-outer clearfix">
              <!--Mobile Navigation Toggler-->

              <div class="mobile-nav-toggler">
                <span class="icon flaticon-menu-1"></span>
              </div>

              <!-- Main Menu -->

              <nav class="main-menu navbar-expand-md navbar-light">
                <div
                  class="collapse navbar-collapse show clearfix"
                  id="navbarSupportedContent"
                >
                  <ul class="navigation clearfix">
                    <li>
                      <router-link to="/"> Anasayfa</router-link>
                    </li>

                    <li class="dropdown">
                      <router-link to="/ozgecmis"> Başkanımız</router-link>

                      <ul>
                        <li>
                          <router-link to="/ozgecmis">
                            Başkan Özgeçmiş</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/eskibaskanlar">
                            Belediye Başkanlarımız</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/mesaj"> Başkan Mesaj</router-link>
                        </li>

                        <li>
                          <router-link to="/baskangaleri">
                            Başkan Galeri</router-link
                          >
                        </li>

                        <li>
                          <router-link to="/iletisim">
                            Başkan'a Sor</router-link
                          >
                        </li>
                      </ul>
                    </li>

                    <li class="dropdown">
                      <a>Hizmetlerimiz</a>
                      <ul>
                        <!-- <li
                          v-if="Object.keys(Linkler).length > 0"
                          class="dropdown"
                        >
                          <a>Hızlı Linkler</a>
                          <ul>
                            <li v-for="(link, index) in Linklerlist" :key="index">
                              <a target="_blank" :href="link.linkaciklamasi">{{
                                link.linkadi
                              }}</a>
                            </li>
                          </ul>
                        </li> -->

                        <!-- <li v-if="domAd == 'localhost'" class="dropdown">
                           <a href="#">E-Hizmetler</a>
                          <ul>
                            <li><a :href="this.$store.state.sandikliBelediye">E-Belediye</a></li>
                             <li><a :href="this.$store.state.sandikliMezarlik">E-Mezarlık</a></li>
                              <li><a :href="this.$store.state.sandikliIlce">E-Kent Rehberi</a></li>
                               <li><a :href="this.$store.state.sandikliImar">E-İmar</a></li>
                          </ul>
                         </li> -->

                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <a :href="Belediyebilgiler.ebelediye"> E-Belediye</a>
                        </li>
                        <li v-if="domAd == 'www.zonguldakkarapinar.bel.tr'">
                          <a
                            href="https://webportal.zonguldakkarapinar.bel.tr"
                            target="_blank"
                          >
                            E-Ödeme</a
                          >
                        </li>
                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <a :href="Belediyebilgiler.nobetciecz">
                            Nöbetçi Eczaneler</a
                          >
                        </li>
                        <li v-if="domAd == 'www.eymir.bel.tr'">
                          <router-link to="/projeler"> Projeler </router-link>
                        </li>
                        <li>
                          <router-link to="/belgeler">
                            Belge Örnekleri
                          </router-link>
                        </li>
                        <li
                          v-if="
                            domAd != 'www.eymir.bel.tr' &&
                            domAd != 'sandikli.zeplinx.com' &&
                            domAd != 'www.kavak.bel.tr'
                          "
                        >
                          <router-link to="/vefat"> Vefat Edenler </router-link>
                        </li>
                        <li v-if="domAd != 'www.kavak.bel.tr'">
                          <router-link to="/evlenenler">
                            Nikah Duyuruları
                          </router-link>
                        </li>
                      </ul>
                    </li>

                    <li class="dropdown">
                      <router-link to="/birimler"> Kurumsal</router-link>

                      <ul>
                        <li v-if="domAd == 'ortahisar.zeplinx.com'">
                          <router-link to="/ozgecmis">
                            Başkan Özgeçmiş</router-link
                          >
                        </li>

                        <li v-if="domAd == 'ortahisar.zeplinx.com'">
                          <router-link to="/mesaj"> Başkan Mesaj</router-link>
                        </li>

                        <li v-if="domAd == 'ortahisar.zeplinx.com'">
                          <router-link to="/baskangaleri">
                            Başkan Galeri</router-link
                          >
                        </li>

                        <li v-if="domAd == 'ortahisar.zeplinx.com'">
                          <router-link to="/iletisim">
                            Başkan'a Sor</router-link
                          >
                        </li>
                        <li v-if="domAd == 'ortahisar.zeplinx.com'">
                          <router-link to="/eskibaskanlar">
                            Başkanlarımız</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            to="/birimler"
                            v-if="domAd == 'sandikli.zeplinx.com'"
                          >
                            Müdürlükler</router-link
                          >
                          <router-link v-else to="/birimler"
                            >Birimler</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/meclisuyeleri">
                            Meclis Üyeleri</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/muhtarlar">
                            Muhtarlarımız</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/encumenlarimiz">
                            Encümenlerimiz</router-link
                          >
                        </li>
                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <router-link to="/kararlar">
                            Meclis Kararları</router-link
                          >
                        </li>
                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <router-link to="/raporlar">
                            Faaliyet Raporları</router-link
                          >
                        </li>
                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <router-link to="/meclisgundemi">
                            Meclis Gündemleri
                          </router-link>
                        </li>
                        <li v-if="domAd == 'www.nurhak.bel.tr'">
                          <router-link to="/etikkomisyonu">
                            Etik Komisyonu
                          </router-link>
                        </li>
                        <li v-if="domAd == 'www.nurhak.bel.tr'">
                          <router-link to="/kvkk">
                            KVKK Aydınlatma Metni
                          </router-link>
                        </li>
                        <li v-if="domAd == 'www.celikhan.bel.tr'">
                          <router-link to="/kvkk">
                            KVKK Aydınlatma Metni
                          </router-link>
                        </li>
                        <li v-if="domAd == 'www.bakacakkadi.bel.tr'">
                          <router-link to="/kvkk">
                            KVKK Aydınlatma Metni
                          </router-link>
                        </li>
                      </ul>
                    </li>
                    <li v-if="domAd == 'www.cukurkuyu.bel.tr'">
                      <a
                        href="http://ebelediye.cukurkuyu.bel.tr"
                        target="_blank"
                      >
                        E-Ödeme</a
                      >
                    </li>
                    <li class="dropdown">
                      <router-link to="/haberler"> Güncel </router-link>

                      <ul>
                        <li>
                          <router-link to="/haberler"> Haberler </router-link>
                        </li>
                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <router-link to="/projeler"> Projeler </router-link>
                        </li>
                        <li>
                          <router-link to="/duyuruilanlar">
                            Duyuru & İlanlar
                          </router-link>
                        </li>
                        <li v-if="domAd == 'www.azatli.bel.tr'">
                          <router-link to="/ihale-ilanlari">
                            İhale İlanları
                          </router-link>
                        </li>
                        <li></li>
                        <li v-if="domAd == 'www.akpazar.bel.tr'">
                          <router-link to="/ihale-ilanlari">
                            İhale İlanları
                          </router-link>
                        </li>
                        <li v-if="domAd == 'www.cavdir.bel.tr'">
                          <router-link to="/ihale-ilanlari">
                            İhale İlanları
                          </router-link>
                        </li>
                        <li v-if="domAd == 'www.kavak.bel.tr'">
                          <router-link to="/vefat"> Vefat Edenler </router-link>
                        </li>
                        <li v-if="domAd == 'www.kavak.bel.tr'">
                          <router-link to="/evlenenler">
                            Nikah Duyuruları
                          </router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="dropdown">
                      <router-link to="/tarihce">{{
                        Belediyebilgiler.belediyeadi
                      }}</router-link>
                      <ul>
                        <li class="dropdown" v-if="Menulist.length > 0">
                          <router-link to="/menulist">
                            Daha Fazla {{ Belediyebilgiler.belediyeadi }}
                          </router-link>
                          <ul>
                            <li v-for="(menu, index) in Menulist" :key="index">
                              <router-link
                                class="dropdown-item"
                                :to="`/menuler/${menu.menuId}`"
                                target="_blank"
                                >{{ menu.menuAdi }}</router-link
                              >
                            </li>
                          </ul>
                        </li>
                        <li>
                          <router-link to="/tarihce"> Tarihce </router-link>
                        </li>
                        <li>
                          <router-link to="/sehitlerimiz">
                            Şehitlerimiz
                          </router-link>
                        </li>
                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <router-link to="/kentrehberi">
                            Kent Rehberi
                          </router-link>
                        </li>
                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <router-link to="/vefat"> Vefat Edenler </router-link>
                        </li>
                        <li v-if="domAd == 'www.eymir.bel.tr'">
                          <router-link to="/galeri">
                            Fotoğraflarla Eymir
                          </router-link>
                        </li>
                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <router-link to="/galeri"> Galeri </router-link>
                        </li>
                        <li v-if="domAd != 'www.eymir.bel.tr'">
                          <router-link to="/belgeler">
                            Belge Örnekleri
                          </router-link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <router-link to="/iletisim"> İletişim</router-link>
                    </li>
                    <li v-if="domAd == 'www.besni.bel.tr'">
                      <router-link to="/kvkk">KVKK</router-link>
                    </li>
                    <li v-if="domAd == 'www.kaymakli.bel.tr'">
                      <router-link to="/kvkk">KVKK</router-link>
                    </li>
                    <li v-if="domAd == 'www.kirka.bel.tr'">
                      <a href="http://kvkk.kirka.bel.tr" target="_blank"
                        >KVKK</a
                      >
                    </li>
                    <li v-if="domAd == 'www.zonguldakkarapinar.bel.tr'">
                      <a
                        href="https://webportal.zonguldakkarapinar.bel.tr"
                        target="_blank"
                        >e-Belediye</a
                      >
                    </li>
                    <li v-if="domAd == 'www.altinbasak.bel.tr'">
                      <a
                        href="https://ebelediye.altinbasak.bel.tr"
                        target="_blank"
                        >e-Belediye</a
                      >
                    </li>
                  </ul>
                </div>
              </nav>
            </div>

            <div
              v-if="
                domAd == 'nurhak.zeplinx.com' ||
                domAd == 'altinyayla.zeplinx.com'
              "
              style="text-align: right"
            >
              <img
                src="/images/ataturkvebayrak.png"
                alt=""
                style="padding-left: 30px; height: 90px"
              />
            </div>

            <div
              v-if="
                domAd != 'nurhak.zeplinx.com' &&
                domAd != 'altinyayla.zeplinx.com'
              "
              class="other-links clearfix"
            >
              <!--Social Links-->
              <div
                v-if="
                  domAd != 'www.idil.bel.tr' && domAd != 'www.akpazar.bel.tr'
                "
                class="social-links-one"
              >
                <ul class="clearfix">
                  <li>
                    <a
                      :href="Belediyebilgiler.face"
                      class="has-tooltip"
                      target="_blank"
                      ><span class="fab fa-facebook-f"></span>
                      <div class="c-tooltip">
                        <div class="tooltip-inner">Facebook</div>
                      </div></a
                    >
                  </li>

                  <li>
                    <a
                      :href="Belediyebilgiler.twit"
                      class="has-tooltip"
                      target="_blank"
                      ><span class="fab fa-twitter"></span>
                      <div class="c-tooltip">
                        <div class="tooltip-inner">Twitter</div>
                      </div></a
                    >
                  </li>
                  <li>
                    <a
                      :href="Belediyebilgiler.insta"
                      class="has-tooltip"
                      target="_blank"
                      ><span class="fab fa-instagram"></span>
                      <div class="c-tooltip">
                        <div class="tooltip-inner">Instagram</div>
                      </div></a
                    >
                  </li>
                </ul>
              </div>

              <!-- cumhur div -->
              <div
                v-if="
                  domAd == 'www.idil.bel.tr' || domAd == 'www.akpazar.bel.tr'
                "
                class="cumhur-div"
              >
                <img class="cumhur-image" src="../assets/cumhur.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--End Header Upper-->

      <!-- Sticky Header  -->

      <div class="sticky-header">
        <div class="auto-container clearfix">
          <!--Logo-->

          <div class="logo pull-left">
            <a href="index.html" title>
              <img src="images/sticky-logo.png" alt title />
            </a>
          </div>

          <!--Right Col-->

          <div class="pull-right">
            <!-- Main Menu -->

            <nav class="main-menu clearfix">
              <!--Keep This Empty / Menu will come through Javascript-->
            </nav>
            <!-- Main Menu End-->
          </div>
        </div>
      </div>
      <!-- End Sticky Menu -->

      <!-- Mobile Menu  -->

      <div class="mobile-menu">
        <div class="menu-backdrop"></div>

        <div class="close-btn">
          <span class="icon flaticon-targeting-cross"></span>
        </div>

        <nav class="menu-box">
          <div class="nav-logo">
            <router-link to="/" title="">
              <img :src="Belediyebilgiler.kapak" alt="" title="" />
            </router-link>
          </div>

          <div class="menu-outer">
            <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
          </div>

          <!--Social Links-->

          <div class="social-links">
            <ul class="clearfix">
              <li>
                <a :href="Belediyebilgiler.face">
                  <span class="fab fa-facebook-square"></span>
                </a>
              </li>

              <li>
                <a :href="Belediyebilgiler.twit">
                  <span class="fab fa-instagram"></span>
                </a>
              </li>

              <li>
                <a :href="Belediyebilgiler.insta">
                  <span class="fab fa-twitter"></span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <!-- End Mobile Menu -->
    </header>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
      Menulist: {},
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    //console.log(this.domAd);
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");

    //await this.$store.dispatch("vericekme", "Linkler");
    //console.log("burası domAd");
    //console.log(this.domAd);

    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    //this.Linkler = this.$store.getters.getLinkler;
    // this.Linklerlist = Object.values(this.Linkler);
    // this.Linklerlist.sort(function(b,a){
    //   return a.linkId - b.linkId;
    // });
    // console.log(this.Linkler);
    // console.log(Object.keys(this.Linkler).length);
    if (this.domAd == "sandikli.zeplinx.com") {
      await this.$store.dispatch("vericekme", "Menuler");
      this.Menuler = this.$store.getters.getMenuler;
      this.Menulist = Object.values(this.Menuler);
      this.Menulist.sort(function (b, a) {
        return a.menuId - b.menuId;
      });
    }
    //this.scripts();
  },
  methods: {},
};
</script>