<template>
  <div>
    <footer class="main-footer">
      <!--Widgets Section-->

      <div class="widgets-section">
        <div class="auto-container">
          <div class="row clearfix">
            <!--Column-->

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="footer-widget links-widget">
                <div class="widget-title">
                  <h4>Başkan</h4>
                </div>

                <div class="widget-content">
                  <ul class="links">
                    <li>
                      <router-link to="/ozgecmis">Başkan Özgeçmiş</router-link>
                    </li>

                    <li>
                      <router-link to="/mesaj">Başkan'dan Mesaj</router-link>
                    </li>

                    <li>
                      <router-link to="/baskangaleri">Başkan Albüm</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--Column-->

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="footer-widget links-widget">
                <div class="widget-title">
                  <h4>Kurumsal</h4>
                </div>

                <div class="widget-content">
                  <ul class="links">
                    <li>
                      <router-link to="/birimler">Birimler</router-link>
                    </li>

                    <li>
                      <router-link to="/meclisuyeleri"
                        >Meclis Üyeleri</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/muhtarlar">Muhtarlarımız</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--Column-->

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="footer-widget num-widget">
                <div class="widget-title">
                  <h4>{{ Belediyebilgiler.belediyeadi }}</h4>
                </div>

                <div class="widget-content">
                  <ul class="num-links">
                    <li>
                      <router-link to="/tarihce">Tarihçe</router-link>
                    </li>

                    <li v-if="domAd != 'www.eymir.bel.tr'">
                      <router-link to="/kentrehberi">Kent Rehberi</router-link>
                    </li>

                    <li v-if="domAd != 'www.eymir.bel.tr'">
                      <router-link to="/vefat">Vefat Edenler</router-link>
                    </li>
                    <li>
                      <router-link to="/galeri">Galeri</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--Column-->

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="footer-widget about-widget">
                <div class="logo">
                  <img :src="Belediyebilgiler.kapak" style="height: 40px" alt />
                </div>

                <div class="address">
                  <h5>{{ Belediyebilgiler.belediyeadi }} Belediyesi</h5>

                  <div class="text">
                    {{ Belediyebilgiler.belediyeadresi }}
                  </div>
                </div>
                <div v-if="domAd == 'www.azatli.bel.tr'">
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/demo1-e1dc2.appspot.com/o/azatli.zeplinx.com1631607417163%2FBelediye%20KVKK%20Politikas%C4%B1.docx?alt=media&token=6b1d77bd-55e2-4264-8008-fe622e05df19"
                    target="_blank"
                    >KVKK</a
                  >
                  <br />
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/demo1-e1dc2.appspot.com/o/azatli.zeplinx.com1631607417163%2FBelediye%20Ayd%C4%B1nlatma%20Metni.docx?alt=media&token=183717c0-0448-4786-be62-6b699b897e86"
                    target="_blank"
                    >Aydınlatma Metni</a
                  >
                  <br />
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/demo1-e1dc2.appspot.com/o/azatli.zeplinx.com1631607417163%2FC%CC%A7erez%20Politikas%C4%B1.docx?alt=media&token=dd9da3bb-3ae1-4daf-8060-a4bc6da7a837"
                    target="_blank"
                    >Çerez Politikası</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Bottom -->

      <div class="footer-bottom">
        <div class="auto-container">
          <div class="inner">
            <div class="copyright">
              Copyrights
              <a href="#"
                >&copy; 2021 {{ Belediyebilgiler.belediyeadi }} Belediyesi</a
              >
              Tüm Hakları Saklıdır.
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>