<template>
  <div v-if="this.showHomeBanner == true" class="container-fluid">
    <div class="banner-home-image">
      <i @click="homeBanner()" class="fa fa-window-close"></i>
      <img class="img" src="./kirkoy.png" alt="banner" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showHomeBanner: true,
    };
  },
  created() {},
  methods: {
    homeBanner() {
      this.showHomeBanner = false;
    },
  },
};
</script>

<style>
.banner-home-image {
  display: flex;
  width: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 160px;
  left: 0px;
  z-index: 9999;
}
.banner-home-image img {
  width: 400px;
  height: 450px;
}
.fa-window-close {
  display: flex;
  position: absolute;
  top: 10px;
  margin-left: 350px;
  font-size: 40px;
  background-color: rgb(173, 4, 4);
}
.fa-window-close:hover {
  cursor: pointer;
}
@media (max-width: 600px) {
  .banner-home-image img {
    width: 300px;
    height: 350px;
  }
  .fa-window-close {
    top: 10px;
    margin-left: 250px;
    font-size: 30px;
  }
}
</style>