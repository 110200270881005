<template>
  <div id="home">
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="banner-section banner-one" style="height: 80vh">
      <!-- <div v-if="domAD == 'localhostasd'" class="slide">
        <div style="position: relative; height: 0; padding-bottom: 56.28%">
          <div id="videoContainment"></div>
          <a
            class="player"
            data-property="{videoURL:'https://youtu.be/iPsUQf2oDLk', mute:true, showControls:false, quality:'default', opacity:1, containment:'#videoContainment', optimizeDisplay:true, loop:true, startAt:0, autoPlay:true, showYTLogo:false, addRaster:false, realfullscreen:true}"
          ></a>
        </div>
      </div> -->

      <div class="banner-carousel owl-theme owl-carousel">
        <div
          class="slide-item"
          v-for="(slide, index) in Slides.Dosyalar"
          :key="index"
        >
          <div id="testslide" class="image-layer">
            <img
              :src="slide.dosyaurl"
              alt=""
              style="height: 100%; object-fit: cover"
            />
          </div>

          <div class="auto-container">
            <div class="content-box">
              <div class="content clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="welcome-section-two"
      :style="{
        backgroundImage:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),' +
          `url('${Belediyebilgiler.anaresim}')`,
        backgroundRepeat: 'round',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      }"
    >
      <div class="upper-row">
        <div class="auto-container">
          <div class="upper-container" style="top: 0px">
            <div v-if="domAD == 'www.besni.bel.tr'" class="row clearfix">
              <!--Feature Block-->

              <div class="featured-block-three col-lg-3 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="fa fa-pencil-alt"></span>
                    </div>

                    <div class="content">
                      <div class="subtitle">Besni Burs</div>

                      <!-- <h4>
                          <a href="https://www.besniburs.com"
                            >Eğitime destek vermek adına
                            oluşturulmuştur.</a
                          >
                        </h4> -->
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <a href="https://www.besniburs.com">Besni Burs</a>
                      </h4>

                      <div class="text">
                        Öğrencilerimizin eğitimine destek vermek adına
                        oluşturulmuştur.
                      </div>
                    </div>
                  </div>

                  <div class="more-link">
                    <a href="https://www.besniburs.com">
                      <span class="flaticon-right-2"></span>
                    </a>
                  </div>
                </div>
              </div>

              <!--Feature Block-->

              <div class="featured-block-three col-lg-3 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="fa fa-file-alt"></span>
                    </div>

                    <div class="content">
                      <div class="subtitle">Web Tapu</div>

                      <!-- <h4>
                          <a href="https://e-hizmet.besni.bel.tr/PaydasKurum"
                            >Tapu işlemlerinizi kolayca halledin.</a
                          >
                        </h4> -->
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <a href="https://e-hizmet.besni.bel.tr/PaydasKurum"
                          >Web Tapu</a
                        >
                      </h4>

                      <div class="text">
                        Tapu işlemlerinizi kolayca halledebilmeniz için
                        oluşturulmuştur.
                      </div>
                    </div>
                  </div>

                  <div class="more-link">
                    <a href="https://e-hizmet.besni.bel.tr/PaydasKurum">
                      <span class="flaticon-right-2"></span>
                    </a>
                  </div>
                </div>
              </div>

              <!--Feature Block-->

              <div class="featured-block-three col-lg-3 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="fa fa-desktop"></span>
                    </div>

                    <div class="content">
                      <div class="subtitle">E-Belediye</div>

                      <!-- <h4>
                          <a href="https://e-hizmet.besni.bel.tr/"
                            >Belediye interaktif hizmetlerine erişin.</a
                          >
                        </h4> -->
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <a href="https://e-hizmet.besni.bel.tr/">E-Belediye</a>
                      </h4>
                      <div class="text">
                        Belediye interaktif hizmetlerine erişin.
                      </div>
                    </div>
                  </div>

                  <div class="more-link">
                    <a href="https://e-hizmet.besni.bel.tr/">
                      <span class="flaticon-right-2"></span>
                    </a>
                  </div>
                </div>
              </div>

              <!--Feature Block-->

              <div class="featured-block-three col-lg-3 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="fa fa-envelope"></span>
                    </div>

                    <div class="content">
                      <div class="subtitle">Web Mail</div>

                      <!-- <h4>
                          <a href="http://webmail.besni.bel.tr/"
                            >Belediye Mail hizmetlerine erişin.</a
                          >
                        </h4> -->
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <a href="https://passport.yandex.com/auth/">Web Mail</a>
                      </h4>
                      <div class="text">Belediye Mail hizmetlerine erişin.</div>
                    </div>
                  </div>

                  <div class="more-link">
                    <a href="https://passport.yandex.com/auth/">
                      <span class="flaticon-right-2"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="row clearfix">
              <!--Feature Block-->

              <div class="featured-block-three col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="flaticon-chat-1"></span>
                    </div>

                    <div class="content">
                      <!-- <div class="subtitle">Bir Fikrim Var</div> -->

                      <h4>
                        <router-link to="/iletisim"
                          >Bir fikrim var.</router-link
                        >
                      </h4>
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <router-link to="/iletisim">Bir Fikrim Var</router-link>
                      </h4>

                      <div class="text">
                        Fikrini bizimle paylaşabilir kentimizi beraber
                        güzelleştirmemize katkı sağlayabilirsin.
                      </div>
                    </div>
                  </div>

                  <div class="more-link">
                    <router-link to="/iletisim">
                      <span class="flaticon-right-2"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <!--Feature Block-->

              <div class="featured-block-three col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="flaticon-user"></span>
                    </div>

                    <div class="content">
                      <!-- <div class="subtitle">Başkana Sor</div> -->

                      <h4>
                        <a :href="Belediyebilgiler.ebelediye">E-Belediye</a>
                      </h4>
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <a :href="Belediyebilgiler.ebelediye">E-Belediye</a>
                      </h4>

                      <div class="text">
                        Online tahsilat ve diğer işlemler için tıklayın.
                      </div>
                    </div>
                  </div>

                  <div class="more-link">
                    <a :href="Belediyebilgiler.ebelediye"
                      ><span class="flaticon-right-2"></span
                    ></a>
                  </div>
                </div>
              </div>

              <!--Feature Block-->

              <div class="featured-block-three col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="flaticon-headphones"></span>
                    </div>

                    <div class="content">
                      <!-- <div class="subtitle">İletişim</div> -->

                      <h4>
                        <router-link to="/iletisim">İletişim</router-link>
                      </h4>
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <router-link to="/iletisim">İletişim</router-link>
                      </h4>
                      <div class="text">
                        Bizimle iletişime geçin sizin için buradayız.
                      </div>
                    </div>
                  </div>

                  <div class="more-link">
                    <router-link to="/iletisim">
                      <span class="flaticon-right-2"></span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="highlights-section">
        <div class="auto-container">
          <div class="sec-title with-separator centered">
            <h2>Güncel Haberler</h2>

            <div class="separator">
              <span class="cir c-1"></span>
              <span class="cir c-2"></span>
              <span class="cir c-3"></span>
            </div>
          </div>

          <div class="carousel-outer">
            <div v-if="kont == true" class="hi-carousel owl-theme owl-carousel">
              <div
                v-for="(haber, index) in Haberler.slice(0, 10)"
                :key="index"
                class="hi-block"
              >
                <div class="inner-box">
                  <div class="upper-info">
                    <div class="count">{{ index + 1 }}</div>

                    <h3>
                      <a>
                        Sonraki
                        <br />haber
                      </a>
                    </h3>
                  </div>
                  <a @click="detayagit(haber.haberId)">
                    <div class="image-box">
                      <figure class="filtre alev">
                        <a href="event-details.html">
                          <img
                            :src="haber.kapak"
                            alt
                            style="height: 430px; object-fit: cover"
                          />
                        </a>
                      </figure>

                      <div class="image-cap clearfix">
                        <h2>
                          <span class="num">0{{ index + 1 }}</span>
                          {{ haber.haberAdi }}
                        </h2>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <section v-if="domAD != 'www.eymir.bel.tr'" class="departments-section">
      <div class="upper-row">
        <div class="bg-layer"></div>

        <div class="auto-container">
          <div class="row clearfix">
            <!--Image Column-->

            <div class="image-column col-xl-3 col-lg-12 col-md-12">
              <div class="inner">
                <figure class="image">
                  <img
                    :src="Belediyebilgiler.anaresim"
                    alt
                    style="max-height: 450px; object-fit: cover"
                  />
                </figure>
              </div>
            </div>

            <div class="right-column col-xl-9 col-lg-12 col-md-12">
              <div class="inner">
                <div class="sec-title with-separator">
                  <h2>İnteraktif Hizmetlerimiz</h2>

                  <div class="separator">
                    <span class="cir c-1"></span>
                    <span class="cir c-2"></span>
                    <span class="cir c-3"></span>
                  </div>
                </div>

                <div class="departments-carousel owl-theme owl-carousel">
                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>01</span>
                      </div>

                      <div class="icon-box">
                        <span class="flaticon-clipboard-with-a-list"></span>
                      </div>

                      <div class="content">
                        <h4>
                          <router-link to="/duyuruilanlar"
                            >Duyuru & İlanlar</router-link
                          >
                        </h4>

                        <div class="read-more">
                          <router-link to="/duyuruilanlar">
                            <span>Görüntüle</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>02</span>
                      </div>

                      <div class="icon-box">
                        <span class="fa fa-folder-open"></span>
                      </div>

                      <div class="content">
                        <h4>
                          <router-link to="/belgeler"
                            >Belge Örnekleri</router-link
                          >
                        </h4>

                        <div class="read-more">
                          <router-link to="/belgeler">
                            <span>Görüntüle</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>03</span>
                      </div>

                      <div class="icon-box">
                        <img
                          src="images/icons/nobetciecz.png"
                          style="max-width: 50px; max-heigth: 50px"
                          alt=""
                        />
                      </div>

                      <div class="content">
                        <h4>
                          <a :href="Belediyebilgiler.nobetciecz"
                            >Nöbetçi Eczaneler</a
                          >
                        </h4>

                        <div class="read-more">
                          <a :href="Belediyebilgiler.nobetciecz">
                            <span>Görüntüle</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>04</span>
                      </div>

                      <div class="icon-box">
                        <img
                          src="images/icons/vefatedenler2.png"
                          style="max-width: 50px; max-heigth: 50px"
                          alt=""
                        />
                      </div>

                      <div class="content">
                        <h4>
                          <router-link to="/vefat">Vefat Edenler</router-link>
                        </h4>

                        <div class="read-more">
                          <router-link to="/vefat">
                            <span>Görüntüle</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>05</span>
                      </div>

                      <div class="icon-box">
                        <span class="flaticon-picture"></span>
                      </div>

                      <div class="content">
                        <h4>
                          <router-link to="/galeri"
                            >{{
                              Belediyebilgiler.belediyeadi
                            }}
                            Fotoğraflar</router-link
                          >
                        </h4>

                        <div class="read-more">
                          <router-link to="/galeri">
                            <span>Görüntüle</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>06</span>
                      </div>

                      <div class="icon-box">
                        <span class="flaticon-user"></span>
                      </div>

                      <div class="content">
                        <h4>
                          <router-link to="/meclisuyeleri"
                            >Meclis Üyeleri</router-link
                          >
                        </h4>

                        <div class="read-more">
                          <router-link to="/meclisuyeleri">
                            <span>Görüntüle</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bottom-text">
                  Mobil uygulamamızı indirerek bir çok hizmete ulaşabilirsiniz
                  <a :href="Belediyebilgiler.android" target="blank"
                    >Google Play Store</a
                  >
                  <a
                    :href="Belediyebilgiler.Ios"
                    target="blank"
                    style="padding-left: 10px"
                    >App Store</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-section">
      <div
        class="image-layer"
        :style="{
          backgroundImage:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.8)),' +
            `url('${Belediyebilgiler.anaresim}')`,
          backgroundRepeat: 'round',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
        }"
      ></div>
      <div class="auto-container">
        <div class="content-box">
          <div class="row">
            <img
              :src="Baskanbilgisi.kapak"
              class="col-md-4"
              alt=""
              style="object-fit: cover"
            />
            <div class="col-md-1"></div>
            <div class="content col-md-7">
              <div class="sec-title light">
                <h2>
                  {{ this.Baskanbilgisi.baskanadi }}
                  <br />
                  {{ this.Baskanbilgisi.baskanslogan }}
                </h2>
              </div>

              <div
                v-if="domAD != 'uzunlu.bel.tr'"
                class="upper-text clearfix"
                v-html="Baskanbilgisi.ozgecmis.slice(0, 500)"
              ></div>

              <div class="read-more">
                <router-link to="/ozgecmis">
                  Devamını Gör
                  <span class="flaticon-right-2"></span>
                </router-link>
              </div>
              <div v-if="domAD == 'uzunlu.bel.tr'" class="upper-text clearfix">
                {{ Baskanbilgisi.mesaj }}
              </div>

              <div class="signature">
                <br />
              </div>

              <div class="info">
                <div class="name">{{ this.Baskanbilgisi.baskanadi }}</div>

                <div class="designation">{{ Baskanbilgisi.baskanslogan }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="events-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="left-column col-xl-8 col-lg-12 col-md-12 col-sm-12">
            <div class="col-inner">
              <div class="sec-title with-separator">
                <h2>Duyurular & İlanlar</h2>

                <div class="separator">
                  <span class="cir c-1"></span>
                  <span class="cir c-2"></span>
                  <span class="cir c-3"></span>
                </div>
              </div>

              <div v-if="Ilanlar.length > 0" class="carousel-box">
                <!--Event Block-->

                <div
                  v-for="(ilan, indx) in Ilanlar.slice(0, 2)"
                  :key="indx"
                  class="event-block"
                >
                  <div class="inner-box">
                    <div class="content-box">
                      <div class="date-box">
                        <div class="date">
                          <span class="day">{{
                            ilan.duyruilanTarihi.split(" ")[0]
                          }}</span>
                          <span class="month">{{
                            ilan.duyruilanTarihi.split(" ")[1]
                          }}</span>
                        </div>
                      </div>

                      <div class="content">
                        <div class="cat-info">
                          <a @click="duyurudetayagit(ilan.duyruilanId)"
                            >Duyuru</a
                          >
                        </div>

                        <h3>
                          <a @click="duyurudetayagit(ilan.duyruilanId)">{{
                            ilan.duyruilanAdi
                          }}</a>
                        </h3>

                        <!-- <div class="location">
                          {{ ilan.duyruilanAciklamasi.slice(0, 150) }}
                        </div> -->

                        <div class="read-more">
                          <a @click="duyurudetayagit(ilan.duyruilanId)"
                            >Görüntüle</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="margin-top: 20px; text-align: center">
                  <router-link to="/duyuruilanlar"
                    >Tüm Duyuruları Gör</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-if="domAD == 'www.eymir.bel.tr'"></div>

          <div
            v-else
            class="right-column col-xl-4 col-lg-12 col-md-12 col-sm-12"
          >
            <div class="service-details">
              <div class="download-links">
                <ul v-if="Kararlar.length > 0">
                  <li
                    v-for="(karar, index) in Kararlar.slice(0, 9)"
                    :key="index"
                  >
                    <a
                      :href="karar.Dosyalar[0].dosyaurl"
                      target="blank"
                      class="clearfix"
                    >
                      <span class="icon fa fa-file-pdf"></span>
                      <span class="ttl">{{ karar.kararAdi }}</span>
                      <span class="info">{{ karar.kararTarihi }}</span>
                    </a>
                  </li>
                </ul>

                <ul v-else>
                  <li>
                    <a>
                      <span class="icon fa fa-file-pdf"></span>
                      <span class="ttl">Karar Bulunamadı</span>
                      <span class="info">-</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Services Section-->

    <section v-if="domAD != 'www.eymir.bel.tr'" class="highlights-section-two">
      <div
        class="image-layer"
        :style="{ backgroundImage: `url('${Belediyebilgiler.anaresim}')` }"
      ></div>

      <div class="auto-container">
        <div
          v-if="Yerler.length > 0"
          class="featured-carousel owl-theme owl-carousel"
        >
          <!--Featured Block -->

          <div
            v-for="(yer, indx) in Yerler"
            :key="indx"
            class="featured-block-five"
          >
            <div class="inner-box">
              <div class="count-box">
                <span>0{{ indx + 1 }}</span>
              </div>

              <div class="content">
                <div class="icon-box">
                  <span class="flaticon-location"></span>
                </div>
                <h3>
                  <a @click="yerdetayagit(yer.yerId)">{{ yer.yerAdi }}</a>
                </h3>

                <!-- <div class="text">{{ yer.yerAciklamasi.slice(0, 125) }}...</div> -->

                <div class="read-more">
                  <a @click="yerdetayagit(yer.yerId)">
                    <span class="flaticon-right-2"></span>
                  </a>
                </div>
              </div>

              <div class="bottom-text">{{ yer.yerAdi }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="Projeler.length > 0" class="news-section">
      <div class="auto-container">
        <div class="sec-title with-separator">
          <h2>Projelerimiz</h2>

          <div class="separator">
            <span class="cir c-1"></span><span class="cir c-2"></span
            ><span class="cir c-3"></span>
          </div>
        </div>
        <div class="carousel-box">
          <div class="news-carousel owl-theme owl-carousel">
            <!--News Block-->
            <div
              v-for="(proje, index) in Projeler"
              :key="index"
              class="news-block"
            >
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image" style="max-height: 208px">
                    <img :src="proje.kapak" alt="" style="object-fit: cover" />
                  </figure>
                </div>
                <div class="lower-box">
                  <div class="upper-info">
                    <h4>
                      <a @click="projedetayagit(proje.projeId)">{{
                        proje.projeAdi
                      }}</a>
                    </h4>

                    <div class="cat-info">
                      <a href="#"
                        ><span class="fa fa-folder"></span
                        >{{ proje.projeDurum }}</a
                      >
                    </div>
                  </div>

                  <div class="meta-info clearfix">
                    <div class="author-info clearfix">
                      <div class="author-icon">
                        <span class="flaticon-user-3"></span>
                      </div>

                      <div class="author-title">
                        {{ Belediyebilgiler.belediyeadi }} Belediyesi
                      </div>

                      <div class="date">{{ proje.projeTarihi }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Facts Section-->

    <section v-if="domAD != 'www.eymir.bel.tr'" class="ext-info-section">
      <div
        class="pattern-layer"
        style="background-image: url(images/background/pattern-2.png)"
      ></div>

      <div class="content-row">
        <div class="auto-container">
          <div class="content">
            <h4>Mobil uygulamamızı indirdiniz mi ?</h4>
            <br />
            <div class="link-box">
              <a
                :href="Belediyebilgiler.android"
                class="theme-btn btn-style-one"
                style="margin-right: 15px"
                target="blank"
              >
                <span class="btn-title">Google Play Store</span>
              </a>
              <a
                :href="Belediyebilgiler.Ios"
                target="blank"
                class="theme-btn btn-style-one"
              >
                <span class="btn-title">App Store</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="domAD == 'kaymakli.zeplinx.com'">
      <div class="py-3 container-fluid" id="animate-area">
        <h3
          style="
            color: black;
            text-align: center;
            padding-bottom: 30px;
            padding-top: 20px;
          "
        >
          Bizi Sosyal Medyadan Takip Edin
        </h3>
        <div class="row px-3" style="text-align: center; padding-bottom: 20px">
          <div class="col-md-4">
            <div
              class="fb-page fb_iframe_widget"
              data-href="https://www.facebook.com/kaymaklibelediyesi"
              data-tabs="timeline"
              data-width="1000"
              data-height="500"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
              fb-xfbml-state="rendered"
              fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fkaymaklibelediyesi&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
            >
              <span style="vertical-align: bottom; width: 455px; height: 500px"
                ><iframe
                  name="f3bab171395474c"
                  width="1000px"
                  height="500px"
                  data-testid="fb:page Facebook Social Plugin"
                  title="fb:page Facebook Social Plugin"
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="true"
                  scrolling="no"
                  allow="encrypted-media"
                  src="https://www.facebook.com/v4.0/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df21b9ebe99e868c%26domain%3Dkaymakli.togdemyazilim.com%26origin%3Dhttp%253A%252F%252Fkaymakli.togdemyazilim.com%252Ff29234e6eaf447c%26relation%3Dparent.parent&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fkaymaklibelediyesi&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
                  style="
                    border: none;
                    visibility: visible;
                    width: 455px;
                    height: 500px;
                  "
                  class=""
                ></iframe
              ></span>
            </div>
          </div>
          <div class="col-md-4">
            <a
              class="twitter-timeline"
              data-lang="tr"
              data-width="500"
              data-height="500"
              data-theme="light"
              href="https://twitter.com/kaymaklibel"
              >Tweets by catalzeytinbld</a
            >
          </div>
          <div class="col-md-4">
            <div
              class="fb-page fb_iframe_widget"
              data-href="https://www.facebook.com/TBB1945"
              data-tabs="timeline"
              data-width="1000"
              data-height="500"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
              fb-xfbml-state="rendered"
              fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTBB1945&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
            >
              <span style="vertical-align: bottom; width: 455px; height: 500px"
                ><iframe
                  name="f3664f734e59cd8"
                  width="1000px"
                  height="500px"
                  data-testid="fb:page Facebook Social Plugin"
                  title="fb:page Facebook Social Plugin"
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="true"
                  scrolling="no"
                  allow="encrypted-media"
                  src="https://www.facebook.com/v4.0/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df27e8c64de73b98%26domain%3Ddemo4.togdemyazilim.com%26origin%3Dhttp%253A%252F%252Fdemo4.togdemyazilim.com%252Ffa94365a573168%26relation%3Dparent.parent&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTBB1945&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
                  style="
                    border: none;
                    visibility: visible;
                    width: 455px;
                    height: 500px;
                  "
                  class=""
                ></iframe
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="domAD == 'gumuscay.zeplinx.com'">
      <div class="py-3 container-fluid" id="animate-area">
        <h3
          style="
            color: black;
            text-align: center;
            padding-bottom: 30px;
            padding-top: 20px;
          "
        >
          Bizi Sosyal Medyadan Takip Edin
        </h3>
        <div class="row px-3" style="text-align: center; padding-bottom: 20px">
          <div class="col-md-4">
            <div
              class="fb-page fb_iframe_widget"
              data-href="https://www.facebook.com/Gümüşçay-Belediye-Başkanlığı-489316781104624"
              data-tabs="timeline"
              data-width="1000"
              data-height="500"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
              fb-xfbml-state="rendered"
              fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FG%C3%BCm%C3%BC%C5%9F%C3%A7ay-Belediye-Ba%C5%9Fkanl%C4%B1%C4%9F%C4%B1-489316781104624&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
            >
              <span style="vertical-align: bottom; width: 455px; height: 500px"
                ><iframe
                  name="f121968118a4aa"
                  width="1000px"
                  height="500px"
                  data-testid="fb:page Facebook Social Plugin"
                  title="fb:page Facebook Social Plugin"
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="true"
                  scrolling="no"
                  allow="encrypted-media"
                  src="https://www.facebook.com/v4.0/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df2aaf63b689822c%26domain%3Ddemo4.togdemyazilim.com%26origin%3Dhttp%253A%252F%252Fdemo4.togdemyazilim.com%252Ff38cb69c834c7cc%26relation%3Dparent.parent&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FG%C3%BCm%C3%BC%C5%9F%C3%A7ay-Belediye-Ba%C5%9Fkanl%C4%B1%C4%9F%C4%B1-489316781104624&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
                  style="
                    border: none;
                    visibility: visible;
                    width: 455px;
                    height: 500px;
                  "
                  class=""
                ></iframe
              ></span>
            </div>
          </div>
          <div class="col-md-4">
            <a
              class="twitter-timeline"
              data-lang="tr"
              data-width="500"
              data-height="500"
              data-theme="light"
              href="https://twitter.com/TBB"
              >Tweets by TBB</a
            >
          </div>
          <div class="col-md-4">
            <div
              class="fb-page fb_iframe_widget"
              data-href="https://www.facebook.com/TBB1945"
              data-tabs="timeline"
              data-width="1000"
              data-height="500"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
              fb-xfbml-state="rendered"
              fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTBB1945&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
            >
              <span style="vertical-align: bottom; width: 455px; height: 500px"
                ><iframe
                  name="f3664f734e59cd8"
                  width="1000px"
                  height="500px"
                  data-testid="fb:page Facebook Social Plugin"
                  title="fb:page Facebook Social Plugin"
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="true"
                  scrolling="no"
                  allow="encrypted-media"
                  src="https://www.facebook.com/v4.0/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df27e8c64de73b98%26domain%3Ddemo4.togdemyazilim.com%26origin%3Dhttp%253A%252F%252Fdemo4.togdemyazilim.com%252Ffa94365a573168%26relation%3Dparent.parent&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTBB1945&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
                  style="
                    border: none;
                    visibility: visible;
                    width: 455px;
                    height: 500px;
                  "
                  class=""
                ></iframe
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="domAD == 'agin.zeplinx.com'">
      <div class="py-3 container-fluid" id="animate-area">
        <h3
          style="
            color: black;
            text-align: center;
            padding-bottom: 30px;
            padding-top: 20px;
          "
        >
          Bizi Sosyal Medyadan Takip Edin
        </h3>
        <div class="row px-3" style="text-align: center; padding-bottom: 20px">
          <div class="col-md-4">
            <div
              class="fb-page"
              data-href="https://www.facebook.com/aginbld/"
              data-tabs="timeline"
              data-width=""
              data-height=""
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Faginbld%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=496729564551734"
                width="340"
                height="500"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </div>
          </div>
          <div class="col-md-4">
            <a
              class="twitter-timeline"
              data-lang="tr"
              data-width="500"
              data-height="500"
              data-theme="light"
              href="https://twitter.com/AliUSLU23?ref_src=twsrc%5Etfw"
              >Tweets by Ağın Belediyesi</a
            >
          </div>
          <div class="col-md-4">
            <div
              class="fb-page fb_iframe_widget"
              data-href="https://www.facebook.com/TBB1945"
              data-tabs="timeline"
              data-width="1000"
              data-height="500"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
              fb-xfbml-state="rendered"
              fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTBB1945&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
            >
              <span style="vertical-align: bottom; width: 455px; height: 500px"
                ><iframe
                  name="f3664f734e59cd8"
                  width="1000px"
                  height="500px"
                  data-testid="fb:page Facebook Social Plugin"
                  title="fb:page Facebook Social Plugin"
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="true"
                  scrolling="no"
                  allow="encrypted-media"
                  src="https://www.facebook.com/v4.0/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df27e8c64de73b98%26domain%3Ddemo4.togdemyazilim.com%26origin%3Dhttp%253A%252F%252Fdemo4.togdemyazilim.com%252Ffa94365a573168%26relation%3Dparent.parent&amp;container_width=455&amp;height=500&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FTBB1945&amp;locale=tr_TR&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=1000"
                  style="
                    border: none;
                    visibility: visible;
                    width: 455px;
                    height: 500px;
                  "
                  class=""
                ></iframe
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      urll: "",
      Haberler: [],
      Slides: {},
      Ilanlar: [],
      Kararlar: [],
      Yerler: [],
      Baskanbilgisi: {},
      Projeler: [],
      loadkont: false,
      kont: false,
      domAD: "",
      Belediyebilgiler: {},
    };
  },
  components: {},
  // linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),
  //background-repeat: round; background-attachment: fixed;
  async created() {
    const domainAd = window.location.hostname;
    this.domAD = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.belediyecek();
    await this.haberlercek();
    await this.$store.dispatch("vericekme", "Genel");
    await this.$store.dispatch("vericekme", "Slides");
    this.Slides = this.$store.getters.getSlides;
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.urll = `url(${this.Belediyebilgiler.kapak})`;

    this.loadkont = true;
    await this.baskancek();
    await this.duyuruilancek();
    await this.kararlarcek();
    await this.yerlercek();
    await this.projelercek();

    this.scriptekleslide();
  },
  methods: {
    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
    projedetayagit(ID) {
      this.$router.push("/projedetay/" + ID);
    },
    yerdetayagit(ID) {
      this.$router.push("/kentrehberidetay/" + ID);
    },
    duyurudetayagit(ID) {
      this.$router.push("/duyurudetay/" + ID);
    },
    async haberlercek() {
      await this.$store.dispatch("vericekme", "Haberler");
      const habermap = this.$store.getters.getHaberler;
      this.Haberler = Object.values(habermap);
      this.Haberler.sort(function (b, a) {
        return a.haberId - b.haberId;
      });
      //console.log(this.Haberler)
      this.kont = true;
    },
    async belediyecek() {},
    async scriptekleslide() {
      // SCRİPT
      let customScriptHome = await document.createElement("script");
      customScriptHome.setAttribute("src", "js/custom-scriptHome.js");
      document.head.appendChild(customScriptHome);
      // SCRİPT
      let customscript2 = await document.createElement("script");
      customscript2.setAttribute("src", "js/main.js");
      document.head.appendChild(customscript2);
      // // SCRİPT
      // let customscript3 = await document.createElement("script");
      // customscript3.setAttribute("src", "js/plugins.js");
      // document.head.appendChild(customscript3);
      // // SCRİPT
      // let customscript4 = await document.createElement("script");
      // customscript4.setAttribute("src", "js/jquery-2.1.1.min.js");
      // document.head.appendChild(customscript4);
    },
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
    },
    async duyuruilancek() {
      await this.$store.dispatch("vericekme", "DuyuruIlanlar");
      const dilanlar = this.$store.getters.getDuyuruIlanlar;
      this.Ilanlar = Object.values(dilanlar);
      this.Ilanlar.sort(function (b, a) {
        return a.duyruilanId - b.duyruilanId;
      });
    },
    async kararlarcek() {
      await this.$store.dispatch("vericekme", "Kararlar");
      const kararlar1 = this.$store.getters.getKararlar;
      this.Kararlar = Object.values(kararlar1);
      this.Kararlar.sort(function (b, a) {
        return a.kararId - b.kararId;
      });
    },
    async yerlercek() {
      await this.$store.dispatch("vericekme", "Yerler");
      const yerler1 = this.$store.getters.getYerler;
      this.Yerler = Object.values(yerler1);
      this.Yerler.sort(function (b, a) {
        return a.yerId - b.yerId;
      });
    },
    async projelercek() {
      await this.$store.dispatch("vericekme", "Projeler");
      const projeler1 = this.$store.getters.getProjeler;
      this.Projeler = Object.values(projeler1);
      this.Projeler.forEach((el) => {
        el.projeDurum = this.projectStatus(el.projeDurum);
      });
      this.Projeler.sort(function (b, a) {
        return a.projeId - b.projeId;
      });
      //console.log(this.Projeler);
    },
    projectStatus(status) {
      switch (status) {
        case 1:
          //console.log("geldi");
          return "Planlanan";
        case 2:
          //console.log("geldi");
          return "Devam Ediyor";
        case 3:
          //console.log("geldi");
          return "Tamamlandı";
        default:
          break;
      }
    },
  },
  computed: {
    teststyle() {
      return {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9))," +
          this.urll,
        "background-repeat": "round",
        "background-attachment": "fixed",
      };
    },
  },
  mounted() {
    if (window.location.hostname == "kaymakli.zeplinx.com") {
      let twitscrpt = document.createElement("script");
      twitscrpt.setAttribute("src", "https://platform.twitter.com/widgets.js");
      document.head.appendChild(twitscrpt);
    }
  },
};
</script>
<style>
#videoContainment {
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  overflow: hidden;
}
</style>
