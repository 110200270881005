import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false
import rizaMetni from '../src/views/rizaMetni.vue'
Vue.component("riza-metni", rizaMetni)
require('firebase/app');
require('@sendgrid/mail');
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
