<template>
  <div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Meclis Kararları</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="events-list">
                <!--Event Block-->

                <div
                  v-for="(karar, index) in Kararlar"
                  :key="index"
                  class="event-block"
                >
                  <div class="inner-box">
                    <div class="content-box">
                      <div class="date-box">
                        <div class="date">
                          <span class="day">{{
                            karar.kararTarihi.split(" ")[0]
                          }}</span>
                          <span class="month">{{
                            karar.kararTarihi.split(" ")[1]
                          }}</span>
                          <span class="year">{{
                            karar.kararTarihi.split(" ")[2]
                          }}</span>
                        </div>
                      </div>

                      <div class="content">
                        <div class="cat-info">
                          <a :href="karar.Dosyalar[0].dosyaurl" target="blank"
                            >Meclis Kararı</a
                          >
                        </div>

                        <h3>
                          <a
                            :href="karar.Dosyalar[0].dosyaurl"
                            target="blank"
                            >{{ karar.kararAdi }}</a
                          >
                        </h3>

                        <div class="location">
                          {{ Belediyebilgiler.belediyeadi }} Belediyesi
                        </div>

                        <div class="read-more">
                          <a :href="karar.Dosyalar[0].dosyaurl" target="blank"
                            >Görüntüle</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--<div style="text-align: center">
                <button class="loadclass">Daha fazla</button>
              </div>-->
            </div>
          </div>

          <!--Sidebar Side-->
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
export default {
  name: "Mecliskararlari",
  components: {
    Sidebar,
  },
  data() {
    return {
      Kararlar: [],
      Belediyebilgiler: {},
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Kararlar");
    const kararmap = this.$store.getters.getKararlar;
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.Kararlar = Object.values(kararmap);
    this.Kararlar.sort(function (b, a) {
      return a.kararId - b.kararId;
    });
    this.loadkontrol = true;
  },
};
</script>
<style>
.loadclass {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  min-width: 180px;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  padding: 18px 30px 12px;
  background: var(--anacolor);
  border-radius: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-family: "Ubuntu", sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  align-items: center;
}
.loadclass:hover {
  background: #222222;
  color: #ffffff;
}
</style>