<template>
  <div>
    <section class="contact-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="right-col col-lg-12 col-md-12 col-sm-12">
            <div class="inner">
              <div class="sec-title with-separator">
                <h2>Başkana Sor</h2>

                <div class="separator">
                  <span class="cir c-1"></span><span class="cir c-2"></span
                  ><span class="cir c-3"></span>
                </div>

                <div class="lower-text">
                  Başkana sor falan filan uzun yazılı bir şey buraya gelecek
                </div>
              </div>

              <div class="default-form form-box">
                <form method="post" action="sendemail.php" id="contact-form">
                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        type="text"
                        name="username"
                        placeholder="Adınız *"
                        required=""
                        value=""
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        required=""
                        value=""
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        type="text"
                        name="username"
                        placeholder="Konu Başlığı *"
                        required=""
                        value=""
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <textarea
                        name="message"
                        placeholder="Mesajınız..."
                        required=""
                      ></textarea>
                    </div>
                  </div>

                  <div class="form-group">
                    <button type="submit" class="theme-btn btn-style-one">
                      <span class="btn-title">Gönder</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
    name:'BaskanaSor'
}
</script>