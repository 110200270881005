<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">  
          <div class="inner-container clearfix">
          <h1 v-if="domAd == 'sandikli.zeplinx.com'">Müdürlükler</h1>
            <h1 v-else>Birimler</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details" style="text-align:center;">
                  <div class="main-image-box">
                    <figure class="image" style="display:inline-flex;">
                      <img :src="BirimBilgisi.kapak" alt style="max-width:250px;display:flex; justify-content:center; flex-direction:row;"/>
                    </figure>
                  </div>

                  <!-- <img :src="BirimBilgisi.kapak" alt /> -->

                  <h2>{{ BirimBilgisi.birimadi }}</h2>

                  <p style="white-space: pre-line" v-html=" BirimBilgisi.birimaciklamasi ">
                  </p>
                  <div>
                     <!-- v-if="BirimBilgisi.Dosyalar.length > 0" -->
                    <h3>
                      Hizmet Standartları
                    </h3>
                    <ul>
                      <li
                        v-for="(dosya, index) in BirimBilgisi.Dosyalar"
                        :key="index"
                      >
                        <a
                          :href="dosya.dosyaurl"
                          target="blank"
                          class="clearfix"
                        >
                          <span
                            class="icon fa fa-file-pdf"
                            style="margin-right:5px"
                          ></span>
                          <span class="ttl">{{ dosya.dosyaId }}</span>
                          <span class="info">{{ dosya.dosyaTarihi }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <Sosyal />
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
import Sosyal from "@/views/Sosyalbanner";
export default {
  name: "Birimdetay",
  data() {
    return {
      BirimBilgisi: {},
      loadkont: false,
      domAd:"",
    };
  },
  components: {
    Sidebar,
    Sosyal,
  },
  async created() {
    const ID = this.$route.params.birimId;
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Birimler");
    const birimmap = this.$store.getters.getBirimler;
    this.BirimBilgisi = birimmap[ID];
    this.loadkont = true;
    //console.log("burası birim bilgisi");
    //console.log(this.BirimBilgisi)

  },
};
</script>
