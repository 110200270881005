<template>
  <div id="app">
    <div class="page-wrapper">
      <Header />
      <router-view />
      <Footer />
      <div v-if="domAd == 'www.kirkoy.bel.tr'">
        <homeBanner />
      </div>
      <div v-if="domAd == 'www.besni.bel.tr'">
        <riza-metni />
      </div>
      <div v-if="domAd == 'www.kaymakli.bel.tr'">
        <riza-metni />
      </div>
      <div class="scroll-to-top scroll-to-target" data-target="html">
        <span class="icon flaticon-up-arrow-angle"></span>
      </div>
    </div>
    <!-- Counter: {{ information.websiteDailyCounter + 1 }} -->
  </div>
</template>
<script>
import firebase from "firebase/app";
import Header from "@/views/Header.vue";
import Footer from "@/views/Footer.vue";
import RizaMetni from "./views/rizaMetni.vue";
import homeBanner from "./components/homeBanner.vue";
export default {
  data() {
    return {
      information: {},
      "--accent-color": "#ffffff",
      domAd: "",
    };
  },
  async created() {
    const test1 = document.documentElement;
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    console.log(this.domAd);
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    const belediye = this.$store.getters.getBelgenel;
    this.information = this.$store.getters.getBelgenel;
    test1.style.setProperty("--anacolor", belediye.anarenk);
    test1.style.setProperty("--headcolor", belediye.headrenk);

    // document.getElementById("ic1").href = belediye.kapak;
    // document.getElementById("ic2").href = belediye.kapak;

    document.title = belediye.belediyeadi + " Belediyesi Resmi Web Sitesi";
    const icon = document.createElement("link");
    icon.rel = "shortcut icon";
    icon.type = "image/x-icon";
    icon.href = belediye.kapak;
    document.head.appendChild(icon);

    const meta = document.createElement("meta");
    meta.content = belediye.belediyeadi + "Resmi Web Sitesi";
    meta.name = "description";
    document.head.appendChild(meta);
    await this.websiteCounter();
    this.scripts();
  },
  components: {
    Header,
    Footer,
    RizaMetni,
    homeBanner,
  },
  methods: {
    async websiteCounter() {
      var newCount = this.information.websiteDailyCounter;
      newCount += 1;
      var domainId = this.$store.getters.getDomainId;
      await firebase
        .firestore()
        .collection("Genel")
        .doc(domainId)
        .set(
          {
            Genel: {
              websiteDailyCounter: newCount,
            },
          },
          { merge: true }
        );
    },
    scripts() {
      let customscript = document.createElement("script");
      customscript.setAttribute("src", "/js/custom-script.js");
      document.head.appendChild(customscript);
    },
  },

  mounted() {
    // let customscript = document.createElement("script");
    // customscript.setAttribute("src", "js/custom-script.js");
    // document.head.appendChild(customscript);
  },
};
</script>
<style scoped>
.header-top-one {
  background: var(--accent-color);
}
</style>>

