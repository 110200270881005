<template>
  <div>
    <div class="share-post">
      <strong>Bizi Sosyal Medyadan Takip Edin</strong>

      <ul class="links clearfix">
        <li class="facebook">
          <a :href="Belediyebilgi.face">
            <span class="icon fab fa-facebook-f"></span>
            <span class="txt">Facebook</span>
          </a>
        </li>

        <li class="twitter">
          <a :href="Belediyebilgi.twit">
            <span class="icon fab fa-twitter"></span>
            <span class="txt">Twitter</span>
          </a>
        </li>

        <li class="linkedin">
          <a :href="Belediyebilgi.insta">
            <span class="icon fab fa-instagram"></span>
            <span class="txt">İnstagram</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return{
            Belediyebilgi:{}
        }
    },
    async created(){
        await this.$store.dispatch("vericekme", "Genel");
        this.Belediyebilgi=this.$store.getters.getBelgenel;
    }
}
</script>