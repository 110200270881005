<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
     

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Başkan Yardımcımız</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="main-image-box">
                    <figure class="image">
                      <img :src="BaskanBilgisi.baskanfotourl" alt />
                    </figure>
                  </div>

                  <h2>{{BaskanBilgisi.baskanadi}}</h2>
                  <h4>{{BaskanBilgisi.baskanslogan}}</h4>

                  <p style="white-space: pre-line;" v-html="BaskanBilgisi.baskanozgecmis"></p>
                </div>

                <div class="share-post">
                  <strong>Başkan Yardımcımız Sosyal Medya Hesapları</strong>

                  <ul class="links clearfix">
                    <li class="facebook">
                      <a :href="BaskanBilgisi.baskanface" target="_blank">
                        <span class="icon fab fa-facebook-f"></span>
                        <span class="txt">Facebook</span>
                      </a>
                    </li>

                    <li class="twitter">
                      <a :href="BaskanBilgisi.baskantwit" target="_blank">
                        <span class="icon fab fa-twitter"></span>
                        <span class="txt">Twiter</span>
                      </a>
                    </li>

                    <li class="linkedin">
                      <a :href="BaskanBilgisi.baskaninsta" target="_blank">
                        <span class="icon fab fa-linkedin-in"></span>
                        <span class="txt">Linkedin</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--Sidebar Side-->

          <Sidebar/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from '@/views/Sidebar'
import firebase from 'firebase/app'
export default {
  name: "Yardimci",
  data(){
      return{
          BaskanBilgisi:{},
          loadkont:false
      }
  },
  components:{
      Sidebar
  },
  async created(){
     await firebase.firestore().collection('Yardimcibilgiler').doc('baskan').get()
      .then((result) => {
          const baskan=result.data();
          this.BaskanBilgisi=baskan;
      }).catch((err) => {
          console.log('baskanhata'+err);
      });
      this.loadkont=true;
  }
};
</script>