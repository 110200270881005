<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Belge Örnekleri</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="service-details">
                    <div v-if="Belgeler.length > 0" class="download-links">
                      <h3>Belge Örnekleri</h3>

                      <ul>
                        <li v-for="(belge, index) in Belgeler" :key="index">
                          <a
                            :href="belge.Dosyalar[0].dosyaurl"
                            target="_blank"
                            class="clearfix"
                          >
                            <span class="icon fa fa-file-pdf"></span>
                            <span class="ttl">{{ belge.belgeAdi }}</span>
                            <span class="info">Görüntüle</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <Sosyal/>
              </div>
            </div>
          </div>

          <!--Sidebar Side-->

          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
import Sosyal from "@/views/Sosyalbanner";
export default {
  name: "Belgeler",
  data() {
    return {
      Belgeler: [],
      loadkont: false,
    };
  },
  components: {
    Sidebar,
    Sosyal
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Belgeler");
    const belgelist = this.$store.getters.getBelgeler;
    this.Belgeler = Object.values(belgelist);
    this.Belgeler.sort(function (b, a) {
      return a.belgeId - b.belgeId;
    });
    this.loadkont = true;
  },
};
</script>